@use 'assets/scss/colors';

.avatar-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	line-height: 0;

	width: var(--width);
	min-width: var(--width);
	height: var(--height);
	min-height: var(--height);
}
